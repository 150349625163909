// libraries
import React from "react"
import Anchor from "../../core/components/anchor"
import Block from "../../core/components/block"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import Row from "../../core/components/row"
import Tagline from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

import portfolioItems from "../../client/data/portfolio.json"

/**
 * Portfolio Page
 * @see https://zpl.io/2ENwGwv
 */
function PortfolioPage() {
  return (
    <article id="page-portfolio" className="page-content">
      {quickMenu}

      <Row className="page-heading" role="banner">
        <Block className="block-content">
          <h2 className="title">Portfolio</h2>
        </Block>
      </Row>

      <Row id="portfolio">
        <Block className="block-content">
          <ul className="portfolio-items">
            {portfolioItems.map(({ name, url, image, location }) => (
              <li className="portfolio-item">
                <Anchor to={url}>
                  <DecorationFluid image={image} disableLazyLoad={true} />

                  <div className="message">
                    <h3 className="title">{name}</h3>
                    <div className="content">{location}</div>
                  </div>
                </Anchor>
              </li>
            ))}
          </ul>
        </Block>
      </Row>
      <Tagline />
    </article>
  )
}

export default PortfolioPage
